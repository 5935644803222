import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import JSONPretty from 'react-json-pretty'
import parse from 'html-react-parser'

import { deleteSurvey } from '@services/surveyservice'

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Toolbar,
  Paper,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
    minWidth: '100%',
  },
  card: {
    margin: theme.spacing(12, 0),
    width: '100%',
  },
  orderpaper: {
    padding: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  ordercardwrapper: {
    marginTop: theme.spacing(2),
  },
  info: {
    fontSize: '16px',
    lineHeight: '1.4',
    color: theme.palette.primary.contrastText,
  },
  message: {
    margin: theme.spacing(4, 2),
  },
  surveyItem: {
    marginBottom: theme.spacing(2),
  },
  subItem: {
    marginLeft: theme.spacing(4),
    '& p': {
      margin: '0',
    },
  },
  surveyContent: {
    fontWeight: '700',
  },
  surveyQuestion: {
    fontWeight: 'bold',
  },
}))

function SurveyCard({ onActionClick, survey }) {
  const classes = useStyles()
  const JSONPrettyMon = require('react-json-pretty/dist/monikai')
  const regstart = /^<p>/
  const regend = /.{0,4}$/

  console.log('survey: ', survey)

  function htmlToElement(html) {
    var template = document.createElement('template')
    html = html.trim() // Never return a text node of whitespace as the result
    template.innerHTML = html
    return template.content.firstChild
  }

  function renderSurveyCards() {
    const obj = JSON.parse(survey.data)

    var array = []

    for (var i in obj) array.push([i, obj[i]])

    return (
      <Paper className={classes.orderpaper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>Angaben zur Person: </span>
            <Grid item xs={8} className={classes.subItem}>
              {obj.angabePerson === 'Andere Funktion'
                ? obj.angabePersonAndereFunktion
                : obj.angabePerson}
            </Grid>
          </Grid>
          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Anzahl Einwohner*innen:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.einwohner}
            </Grid>
          </Grid>
          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Name der Stadt/Gemeinde:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.stadt}
            </Grid>
          </Grid>
          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Auf Dialoginitiative Dspricht5G aufmerksam geworden durch:
            </span>{' '}
            {obj.aufmerksam.map((value, index, key) => {
              return (
                <Grid item xs={8} className={classes.subItem}>
                  {console.log(value)}
                  {value === 'internetrecherche'
                    ? 'Internetrecherche'
                    : value === 'website'
                    ? 'Website'
                    : value === 'facebook'
                    ? 'Facebook'
                    : value === 'twitter'
                    ? 'Twitter'
                    : value === 'instagram'
                    ? 'Instagram'
                    : value === 'youtube'
                    ? 'Youtube'
                    : value === 'aufmerksamEmpfehlung'
                    ? obj.aufmerksamEmpfehlung === 'Sonstige:'
                      ? 'Sonstige: ' + obj.aufmerksamEmpfehlungTextInput
                      : obj.aufmerksamEmpfehlung
                    : value}
                </Grid>
              )
            })}
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Kanäle der Kontaktaufnahme:
            </span>{' '}
            {obj.kanal.map((value, index, key) => {
              return (
                <Grid item xs={8} className={classes.subItem}>
                  {console.log(value)}
                  {value === 'kanalMail'
                    ? 'Mail'
                    : value === 'kanalTelefon'
                    ? 'Telefon'
                    : value === 'kanalWebsite'
                    ? 'Website'
                    : value === 'kanalSonstiges'
                    ? 'Sonstige: ' + obj.kanalSonstigesTextInput
                    : value}
                </Grid>
              )
            })}
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Wie schnell wurde auf Ihre Anfrage reagiert:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.reaktion}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Wie zufrieden sind Sie mit der Planung der Veranstaltung:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.zufriedenPlanung}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Was hat Ihnen im Vorfeld der Veranstaltung gefallen oder Ihnen
              gefehlt:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.gefallenPlanung
                ? parse(obj.gefallenPlanung)
                : 'keine Angabe'}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Wie zufrieden sind Sie mit der Durchführung der Veranstaltung:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.zufriedenDurchfuehrung}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Was hat Ihnen an der Veranstaltung gefallen oder Ihnen gefehlt:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.gefallenDurchfuehrung
                ? parse(obj.gefallenDurchfuehrung)
                : 'keine Angabe'}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Welche Initiativen bzw. Institutionen haben Ihre Veranstaltung vor
              Ort oder digital begleitet:
            </span>{' '}
            {obj.initiativen.map((value) => {
              return (
                <Grid item xs={8} className={classes.subItem}>
                  {value === 'dialog'
                    ? 'Dialoginitiative Deutschland spricht über 5G: ' +
                      obj.dialog
                    : value === 'kemf'
                    ? 'Kompetenzzentrum Elektromagnetische Felder KEMF: ' +
                      obj.kemf
                    : value === 'bnetza'
                    ? 'Bundesnetzagentur BNetzA: ' + obj.bnetza
                    : value === 'betreiber'
                    ? 'Mobilfunknetzbetreiber "' +
                      obj.initiativenBetreiberTextInput +
                      '": ' +
                      obj.betreiber
                    : value === 'behoerden'
                    ? 'Mobilfunknetzbetreiber "' +
                      obj.initiativenBehoerdenTextInput +
                      '": ' +
                      obj.behoerden
                    : value}
                </Grid>
              )
            })}
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Wie zufrieden sind Sie mit den Broschüren und Materialien der
              Dialoginitiative:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.zufriedenMaterial}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Welche Inhalte fehlen Ihnen noch in den Informationsmaterialien:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.informationen ? parse(obj.informationen) : 'keine Angabe'}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Welche inhaltlichen Schwerpunkte der Referierenden waren für Sie
              und die Bürger*innen besonders informativ und anschaulich:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.schwerpunkte ? parse(obj.schwerpunkte) : 'keine Angabe'}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Würden Sie die Dialogbegleitung durch Deutschland spricht über 5G
              weiterempfehlen:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj?.weiterempfehlung}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Sind Sie damit einverstanden, dass die Veranstaltung auf einer
              Übersicht in Form einer Deutschlandkarte auf der Website der
              Dialoginitiative mit kurzer Zusammenfassung eingetragen wird?
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj?.markierungKarte}
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.surveyItem}>
            <span className={classes.surveyQuestion}>
              Anmerkungen oder Anregungen an das Dialogbüro:{' '}
            </span>{' '}
            <Grid item xs={8} className={classes.subItem}>
              {obj.anmerkungen ? parse(obj.anmerkungen) : 'keine Angabe'}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <Card elevation={3} className={classNames(classes.card)}>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          direction="row"
          spacing={1}
          wrap="nowrap"
          justify="space-between"
          alignContent="flex-end"
        >
          <Grid item container direction="column" xs={8} spacing={0}>
            <Grid item container spacing={2}>
              <Grid item>
                <Typography className={classes.info}>{survey.id}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.info}>
                  {survey.timestamp}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>

      <CardContent>
        <Grid
          className={classes.ordercardwrapper}
          container
          wrap="nowrap"
          spacing={2}
          direction="column"
        >
          {!!survey.message && (
            <Typography
              className={classes.message}
              dangerouslySetInnerHTML={{ __html: survey.message }}
            />
          )}
          {/*      <Grid item>
            <JSONPretty data={survey.data} theme={JSONPrettyMon}></JSONPretty>
          </Grid> */}

          {renderSurveyCards()}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteSurvey(survey.id).then(() => {
                  if (onActionClick) onActionClick()
                })
              }}
            >
              <FormattedMessage id="button.deletesurvey" />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

SurveyCard.propTypes = {
  onActionClick: PropTypes.func,
  order: PropTypes.any,
}

export default SurveyCard
