import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { getSurveys } from '@services/surveyservice'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'

import SurveyCard from '@objects/cards/survey'

export default function SurveysPage() {
  const intl = useIntl()
  const [surveysList, setSurveysList] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateSurveysList()
  }, [])

  function onActionClick() {
    updateSurveysList()
  }

  function updateSurveysList() {
    setLoading(true)
    getSurveys().then((surveys) => {
      setSurveysList(surveys)
      setLoading(false)
    })
  }

  console.log('surveysList: ', surveysList)

  function renderCards() {
    if (!surveysList?.length) return

    return surveysList.map((survey) => (
      <SurveyCard
        key={survey.id}
        survey={survey}
        onActionClick={onActionClick}
      />
    ))
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'page.gdpr.note',
        })}
      />
      <LoadingSkeleton loading={loading} type="card" number={4}>
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}
