import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '500px',
  },
}))

function TableSkeleton() {
  const classes = useStyles()
  return <Skeleton variant="rect" className={classes.root} />
}

export default TableSkeleton
