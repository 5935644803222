import apiClient from './basicapi'

const apiRoot = 'survey/admin/'

export function getSurveys() {
  return apiClient.get(`${apiRoot}Surveys`).then((res) => res.data)
}

export function deleteSurvey(id) {
  return apiClient.delete(`${apiRoot}Surveys/${id}`)
}
