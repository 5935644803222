import React from 'react'
import PropTypes from 'prop-types'
import { Fade } from '@material-ui/core'

import Answer from './types/answer'
import Question from './types/question'
import TableSkeleton from './types/table'

function LoadingSkeleton({
  className,
  children,
  type,
  number,
  loading,
  childClassName,
}) {
  function renderType(idx) {
    switch (type) {
      case 'question':
        return <Question className={childClassName} key={`question${idx}`} />
      case 'answer':
        return <Answer className={childClassName} key={`answer${idx}`} />
      case 'table':
        return <TableSkeleton className={childClassName} key={`table${idx}`} />
      default:
        return <Question className={childClassName} key={`question${idx}`} />
    }
  }

  function renderItem() {
    if (loading) {
      let row = []
      for (let i = 0; i < (number || 1); i++) {
        row.push(renderType(i))
      }
      return row
    }
    return (
      <Fade className={className} in={!loading} timeout={800}>
        <div>{children}</div>
      </Fade>
    )
  }

  return (
    <div data-testid="skeleton" className={className}>
      {renderItem()}
    </div>
  )
}

LoadingSkeleton.propTypes = {
  className: PropTypes.string,
  childClassName: PropTypes.string,
  children: PropTypes.any,
  number: PropTypes.number,
  type: PropTypes.string,
  loading: PropTypes.bool,
}

export default LoadingSkeleton
